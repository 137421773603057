.dashboard {
}
.dashboard-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}
.dsb-card {
  background: #fff;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  padding: 10px;
  margin-top: 20px;
  border-radius: 10px;
  min-width: 270px;
}
.dsb-card-header {
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}
.dsb-card-icon {
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(64, 64, 64, 0.4) !important;
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  margin-top: -30px;
  margin-bottom: 12px;
}
.dsb-card-body {
  margin-top: 15px;
  position: relative;
  min-height: 30px;
}
.timer-wrap {
  min-width: 250px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.timer-wrap h5 {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.timer-wrap h5 div {
  width: 37px;
  text-align: center;
}
