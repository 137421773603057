.timesheet {
  padding: 10px 15px 0 20px;
}
.timesheet-header {
  display: flex;
  justify-content: space-between;
}

.moni-d-header {
  display: flex;
  align-items: center;
  padding: 10px 20px 0;
  background: #fff;
  margin: 20px 8px;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.moni-d-header h4 {
  font-weight: 500;
}
.filter-wrap {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.moni-data {
  width: 100%;
  position: relative;
}

.user-selection-container {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.user-selection-title {
  font-size: 20px;
}

.moni-d-main-container {
  margin-top: 15px;
  padding-top: 20px;
  border-top: 1px solid rgba(156, 156, 156, 0.502);
}
.moni-d-wrap {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}
.moni-d-card {
  width: 230px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 15%);
  padding: 5px;
}
.moni-d-time {
  font-weight: 600;
  margin: 5px 0;
}
.moni-d-img {
}
.moni-d-img img {
  width: 100%;
  border-radius: 3px;
}

/**********************************
* Report Inactive Popup
**********************************/

.inactive-detail-popup {
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(42 89 209 / 20%);
  backdrop-filter: blur(2px);
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: 9999;
}
.inactive-p-wrap {
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 20%);
  margin: 100px auto;
  width: 95%;
  max-width: 900px;
  position: relative;
  overflow: hidden;
}
.p-close-btn {
  position: absolute;
  top: -30px;
  right: -30px;
  width: 80px;
  height: 80px;
  background: #0d3f66;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: flex-end;
  padding: 17px;
  cursor: pointer;
}
.inactive-p-header {
  padding: 20px 0;
  margin: 0 20px;
  text-align: center;
  border-bottom: 1px solid #0d3f66;
}
.inactive-p-header h4 {
  margin: 0;
}
.inactive-p-body {
  padding: 20px;
  padding-bottom: 0;
}
.mui-popup-datatable {
  box-shadow: none !important;
}
