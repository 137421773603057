.Navbar {
  width: calc(100% - 10px);
  padding: 0 20px;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 20%);
  z-index: 9999;
  background: #0d3f66;
  /* backdrop-filter: blur(2px); */
  position: fixed;
  margin: 5px;
  border-radius: 5px;
}
.Navbar-wrap {
  height: 100%;
  display: flex;
  align-items: center;
}
.Navbar-menu-btn {
  display: none !important;
  position: absolute !important;
  left: 15px;
  min-width: 30px !important;
  height: 40px;
  border-radius: 20px !important;
  color: #fff !important;
}
.Navbar a {
  font-size: 17px;
  color: #fff;
  text-decoration: none;
}
.Navbar-logo {
  height: 50px;
}
.Navbar-logo-text {
  font-size: 17px;
  font-weight: bold;
  margin-left: 10px;
  letter-spacing: 2px;
}
.Navbar-logo-text span {
  color: #f98032;
  font-size: 22.22px;
}
.Navbar-right-btns {
  margin-left: auto;
  padding: 5px 0;
  display: flex;
  align-items: center;
}
.Nav-btns {
  font-size: 13px !important;
  color: white !important;
  height: 40px;
  margin-left: 10px !important;
}
.profile-wrap {
  position: relative;
}
.profile-pic {
  width: min(30px, 30px);
  height: 30px;
  border-radius: 50%;
  margin-left: 10px;
  background: #1652a3;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 10%);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  outline: none;
  border: none;
  line-height: initial;
  text-transform: capitalize;
}
.account-pop {
  position: absolute;
  top: 50px;
  right: 0;
  width: 200px;
  border-radius: 5px;
  background: #fff;
  font-weight: 500;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  overflow: hidden;
  z-index: 99999;
  animation: open 0.25s ease !important;
}
@keyframes open {
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}
.account-pop-header {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #d7d7d7;
  font-size: 14px;
  background: #0d3f66;
  color: #fff;
}
.account-pop-body {
  margin: 7px 0;
}
.account-pop-item {
  letter-spacing: 0.3px;
  color: rgb(75, 75, 75);
  padding: 7px 13px;
  font-size: 13px;
  cursor: pointer;
  margin: 5px;
  border-radius: 5px;
}
.account-pop-item:hover {
  background: #0d3f66;
  color: #fff;
}
.account-pop-item svg {
  margin-right: 7px;
  height: 17px;
}
.logout-wrap {
  margin-top: 7px;
  border-top: 1px solid #d7d7d7;
}
.logout {
  margin-top: 7px;
  color: red;
}
.logout:hover {
  background: #fff;
  color: red;
}
@media screen and (max-width: 991px) {
  .Navbar-menu-btn {
    display: block !important;
  }
  .Navbar-logo {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .Navbar-wrap {
    /* flex-direction: column; */
    justify-content: space-between;
    padding: 5px;
  }
  .Navbar-right-btns {
    margin: 0;
  }
  .Navbar-right-btns > a {
    display: none;
  }
  .Navbar-logo {
    margin-left: 0;
  }
}
