.alert-layer {
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  display: grid;
  place-items: center;
}
.alert-box {
  background: #fff;
  width: 100%;
  max-width: 600px;
  border-radius: 7px;
  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  animation-duration: 0.3s;
}
.alert-box.ani-scale {
  animation-name: scale;
}
.alert-box.ani-slideTop {
  animation-name: slideTop;
}
.alert-box.ani-slideBottom {
  animation-name: slideBottom;
}
@keyframes scale {
  0% {
    scale: 0;
  }
  100% {
    scale: 1;
  }
}
@keyframes slideTop {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideBottom {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}
.alert-content {
  padding: 20px;
}
.alert-icon {
  display: grid;
  place-items: center;
  padding: 10px;
}
.alert-icon svg {
  font-size: 50px;
}

.alert-icon .c-error {
  color: #e2293c;
}
.alert-icon .c-success {
  color: #198754;
}
.alert-icon .c-warning {
  color: orange;
}

.alert-title {
  font-family: sans-serif;
  font-weight: 500;
  margin-top: 15px;
  font-size: 1.25rem;
  text-align: center;
}
.alert-extra-info {
  font-size: 0.9rem;
  line-height: 25px;
  text-align: center;
}
.alert-btns-wrap {
  background: rgba(240, 240, 240, 0.432);
  padding: 15px;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  row-gap: 15px;
}
.alert-btn {
  border: 0;
  outline: none;
  border-radius: 7px;
  padding: 7px 20px;
  font-size: 0.9rem;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 15%);
}

.b-default {
  background: #eee;
  color: #000;
}
.b-default:hover {
  background: rgb(194, 194, 194);
}
.b-success {
  background: #198754;
  color: #fff;
}
.b-error {
  background: #e2293c;
  color: #fff;
}
.b-warning {
  background: orange;
  color: #fff;
}
