.sidebar {
  height: 100vh;
  padding: 60px 0 5px;
}
.sidebar-wrap {
  height: 100%;
  width: 0;
  background: #0d3f66;
  color: #ffffffd1;
  /* transform: translateX(-270px); */
  /* transition: all 0.3s ease; */
  backdrop-filter: blur(2px);
  box-shadow: 1px 2px 10px rgb(0 0 0 / 20%);
  margin-left: 5px;
  border-radius: 5px;
  padding: 5px;
}
.sidebar-wrap.open {
  max-width: 270px;
  min-width: 270px;
  /* transform: translateX(0); */
}
.sidebar-open-wrap {
  height: 100%;
  min-width: 15px;
  margin-right: 25px;
  background: #0d3f66;
  margin-left: 5px;
  border-radius: 5px;
}
.sidebar-open-btn {
  height: 30px;
  width: 30px;
  background: #0d3f66;
  position: absolute;
  border-radius: 50%;
  display: grid;
  place-items: center;
  text-align: center;
  margin-top: 5px;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 60%);
  cursor: pointer;
}
.sidebar-open-btn svg {
  height: 15px;
  color: #fff;
}
.current-workspace {
  background: #004970;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 13px;
  border-radius: 5px;
}
.current-workspace-icon {
  height: 35px;
  width: 35px;
  background: #1652a3;
  border-radius: 3px;
  font-size: 20px;
  text-transform: uppercase;
  display: grid;
  place-items: center;
  box-shadow: 1px 2px 2px rgb(0 0 0 / 10%);
}
.current-workspace span {
  font-size: 16px;
  text-transform: capitalize;
}
.current-workspace-show-btn {
  height: 30px;
  width: 30px;
  display: grid;
  place-items: center;
  margin-left: auto;
  border-radius: 3px;
  cursor: pointer;
}
.current-workspace-show-btn:hover {
  background: #1652a3;
}
.current-workspace-show-btn svg {
  height: 15px;
}
.sidebar-item-wrap {
  padding: 10px 0;
  font-size: 14px;
}
.sidebar-item {
  display: flex;
  align-items: center;
  padding: 12px 13px;
  gap: 7px;
  margin: 2px 7px;
  border-radius: 5px;
}
.sidebar-item:hover {
  background: #1653a3ab;
}
.sidebar-item.active {
  background: #1652a3;
}
.sidebar-item-star-btn,
.sidebar-item-comment-btn {
  height: 20px;
  width: 20px;
  color: #fff;
  display: inherit;
  margin-right: 5px;
}
.sidebar-item-star-btn svg {
  height: 100%;
  width: 10%;
}
.sidebar-item-comment-btn svg {
  height: 18px;
  width: 18px;
  margin-top: 3px;
}
.sidebar-item-comment-btn {
  margin-left: auto;
}
.sidebar-item-heading {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-add-btn {
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}
.sidebar-add-btn:hover {
  background: #1652a3;
}
