.img-404 {
  animation: img-404 1s infinite;
}

@keyframes img-404 {
  0% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(5px);
  }
}
