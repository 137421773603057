.login {
  height: 100vh;
  overflow: auto;
}

.login .form-container {
  width: 100%;
  min-height: 100%;
  background: #fff;
  display: grid;
  place-items: center;
}

.form-content {
  width: min(100%, 500px);
  padding: 30px;
}

.signin-form {
  width: 100%;
  overflow: hidden;
  align-items: center;
}
.form-title {
  line-height: 1.66;
  margin: 0;
  padding: 0;
  font-weight: 700;
  color: #222;
  font-size: 30px;
  margin-bottom: 33px;
  text-align: center;
}

.l-form-group {
  position: relative;
  overflow: hidden;
}

.text-field {
  width: 100%;
  display: block;
  border: none;
  border-bottom: 1px solid #999;
  padding: 11px 30px 11px 35px;
  box-sizing: border-box;
}
.text-field::-webkit-input-placeholder {
  color: #999;
}
.pass-handle {
  position: absolute;
  top: 25px;
  right: 22px;
  cursor: pointer;
}
.agree-term {
  display: inline-block;
  width: auto;
}

.social-login {
  text-align: center;
  margin-top: 35px;
}
.social-label {
  display: inline-block;
  font-weight: 500;
}
.social-btn {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 12px;
  width: 280px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.9);
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  border: none;
}
.social-btn .s_title {
  margin-left: 12px;
}
.socials-google {
  background-color: #fff;
  color: rgb(93, 93, 93);
}
.socials-facebook {
  background-color: #1778f2;
  color: #fff;
}
.socials-facebook:hover {
  color: #fff;
}

.forgot-link {
  text-align: right;
  margin-right: 10px;
  color: #0d6efd;
}
.register-link {
  text-align: center;
  margin-top: 20px;
}

.error {
  color: red;
  font-size: 13px;
}

.kep-login-facebook.metro {
  height: 40px;
  font-size: 14px;
  padding-left: 49px;
  width: 280px;
  display: flex;
  align-items: center;
  border-radius: 10px !important;
  font-weight: 500;
  font-family: inherit;
  text-transform: capitalize;
  background: #1778f2;
  position: relative;
  border: none;
  box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.9);
}
.kep-login-facebook.metro::before {
  content: "";
  /* background: url('../../images/fb-logo.png'); */
  background-size: cover;
  position: absolute;
  height: 25px;
  width: 25px;
  left: 12px;
}
