* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  color: #212b36;
  line-height: 1.5;
  font-size: 1rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  background-color: #f0f2f5;
}

:root {
  --hover-bg: rgba(9, 30, 66, 0.08);
  --btn-shadow: 1px 2px 3px rgb(0 0 0 / 10%);
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

label {
  font-weight: 500;
}

input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0;
}
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}
input[type="checkbox"] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}
input[type="radio"] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(87, 87, 87);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #282c34;
}
/* } */

.max-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

/****************************************************
* Snackbar
****************************************************/

.custom-snakbar {
  top: 80px !important;
}

/****************************************************
* Dialog
****************************************************/

.MuiModal-root {
  z-index: 99999 !important;
}
.dialog-title {
  color: #000000;
}

/**********************************
* Custom
**********************************/

.title-underline {
  position: relative;
  padding-bottom: 10px;
}
.title-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 70%;
  height: 3px;
  background: orangered;
  border-radius: 50%;
}
.cursor-pointer {
  cursor: pointer !important;
}
/**********************************
* App
**********************************/

.app {
  height: 100vh;
}
.app-wrap {
  display: flex;
}
.app-content {
  height: calc(100vh - 60px);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 60px;
}

p {
  margin: 0;
}

/**********************************
* MUI
**********************************/

.MuiPaper-root {
  box-shadow: 0px 2px 10px rgb(0 0 0 / 10%) !important;
}
